import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ResortList from "../views/ResortList.vue";
import Resort from "../views/Resort.vue";
import PayOk from "../views/PayOk.vue";
import AddTraveler from "../views/AddTraveler.vue";
import Payment from "../views/Payment.vue";
import Profile from "../views/Profile.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/resortlist",
    name: "ResortList",
    component: ResortList,
  },
  {
    path: "/resort",
    name: "Resort",
    component: Resort,
  },
  {
    path: "/pay-ok",
    name: "PayOk",
    component: PayOk,
  },
  {
    path: "/resort-addtraveler",
    name: "AddTraveler",
    component: AddTraveler,
  },
  {
    path: "/resort-payment",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
