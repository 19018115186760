<template>
  <div>
    <Header />
    <!-- <modal
      name="pay-checkout"
      :width="400"
      height="auto"
      :adaptive="false"
      :scrollable="true"
      @before-close="bCloseModal"
      
    >
      <ModalStripeRenovation :contractId="ContratoId" v-if="ContratoId"/>
    </modal> -->
    <div id="wrapper" bis_skin_checked="1">
      <div class="profile main pb-40" id="profile" bis_skin_checked="1">
        <div class="o-container o-container--large" bis_skin_checked="1">
          <form id="dashboardForm" name="dashboardForm" class="dashboard-form">
            <div
              class="o-flexbox@lg u-gap--bigger pt-20 pt-40@lg"
              bis_skin_checked="1"
            >
              <aside class="u-1/4@lg pb-20">
                <div
                  class="o-flexbox o-flexbox--generic-row u-align-items--end"
                  bis_skin_checked="1"
                >
                  <h1
                    class="u-font-size--bigger u-font-weight--600 m-0 pb-20@lg"
                  >
                    Panel de usuario
                  </h1>
                  <div
                    class="u-display--inline-block u-display--none@lg u-text-align--center"
                    bis_skin_checked="1"
                  >
                    <span
                      class="u-display--block u-font-size--tiny u-line-height--1"
                      >MENU</span
                    ><button
                      class="navbar-toggle collapsed c-button--userheader u-text-align--center u-float--none u-box-shadow m-0 pl-10 pr-10"
                      data-target="#userCollapsableHeader"
                      data-toggle="collapse"
                      type="button"
                    >
                      <i
                        class="fa-regular fa-user-gear u-font-size--middle clr--white"
                      ></i>
                    </button>
                  </div>
                </div>
                <div
                  class="o-position--sticky@md u-zindex--32"
                  style="top: 20px"
                  bis_skin_checked="1"
                >
                  <div
                    id="userCollapsableHeader"
                    class="navbar-collapse collapse p-0 pt-20 pt-0@lg"
                    bis_skin_checked="1"
                  >
                    <a
                      id="dashboardForm:dashboard-data"
                      class="ui-commandlink ui-widget o-flexbox u-gap--small u-align-items--center o-box--small u-border-radius active c-button--solid--default"
                      style="cursor: pointer"
                    >
                      <i class="fa-regular fa-fw fa-user"></i>
                      Mis datos
                    </a>
                    <!-- <a
                      id="dashboardForm2:dashboard-data"
                      class="ui-commandlink ui-widget o-flexbox u-gap--small u-align-items--center o-box--small u-border-radius"
                      style="cursor: pointer"
                    >
                      <i class="fa-regular fa-fw fa-dollar-sign"></i>
                      Renovaciones
                    </a> -->
                  </div>
                </div>
              </aside>
              <div class="u-3/4@lg" bis_skin_checked="1">
                <div
                  class="c-page-hero c-page-hero--small"
                  bis_skin_checked="1"
                >
                  <div
                    class="c-page-hero__wrapper-img c-page-hero__wrapper-img--disable-mask u-border-radius"
                    bis_skin_checked="1"
                  >
                    <img
                      data-src="https://booking.kovayvacations.com.mx/javax.faces.resource/images/fullwidth2.jpg"
                      src="https://booking.kovayvacations.com.mx/javax.faces.resource/images/fullwidth2.jpg"
                      data-index=""
                      data-error-src="https://booking.kovayvacations.com.mx/javax.faces.resource/images/no-photo-XS.jpg"
                      onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                      loading="lazy"
                      alt=""
                      width=""
                      height=""
                      onclick=""
                      style=""
                      class="lazy-img c-page-hero__img u-image-object--cover u-image-object--full loaded"
                      data-ll-status="loaded"
                    />
                  </div>
                </div>
                <div
                  class="o-flexbox@sm o-flexbox--generic-row@sm u-align-items--center@sm u-align-items--end@md pb-20"
                  bis_skin_checked="1"
                >
                  <div
                    class="o-flexbox u-gap--small u-align-items--center"
                    bis_skin_checked="1"
                  >
                    <i
                      class="o-flexbox u-justify-content--center u-align-items--center u-border-radius--round u-border--full bdr-clr--white clr--dark-gray bg-clr--lightest-gray fa-regular fa-user u-font-size--giant u-border-width--4 u-position--relative u-zindex--2"
                      style="width: 110px; height: 110px; margin-top: -25px"
                    ></i>
                    <div
                      class="pb-10"
                      style="width: calc(100% - 120px)"
                      bis_skin_checked="1"
                    >
                      <h2
                        class="u-font-size--big u-font-size--bigger@sm u-font-weight--600 u-line-height--1 m-0"
                      >
                        {{ member.Nombre }} {{ member.ApellidoPaterno }}
                      </h2>
                      <span
                        class="u-font-size--small u-font-size--default@md u-line-height--1"
                      >
                        <b>LoginID:</b> {{ member.ContratoId.LoginID }} |
                        <b>{{ $func.parseIso("contract") }}:</b>
                        {{ member.ContratoId.Numero }}
                      </span>
                    </div>
                  </div>
                  <!-- <div
                    class="u-display--none u-display--block@sm pb-20@md"
                    bis_skin_checked="1"
                  >
                    <a
                      id="dashboardForm:j_id_3j"
                      href="#"
                      class="ui-commandlink ui-widget c-button bdr-clr--brand mb-5@md"
                      aria-label=""
                      onclick='PrimeFaces.ab({s:"dashboardForm:j_id_3j",f:"dashboardForm",u:"dashboardForm"});return false;'
                    >
                      Mis reservas
                    </a>
                  </div> -->
                </div>
                <div
                  class="u-display--none u-display--block@sm o-container o-container--large o-container--fit pb-30"
                  bis_skin_checked="1"
                >
                  <div
                    class="o-flexbox@sm o-flexbox--generic-row@sm u-gap--default u-justify-content--between"
                    bis_skin_checked="1"
                    style="display: none"
                  >
                    <div
                      class="o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-line-height--1 u-1/1"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small"
                        bis_skin_checked="1"
                      >
                        <i
                          class="fa-light fa-calendar-check u-font-size--huge clr--dark-gray"
                        ></i>
                        <div class="u-text-align--right" bis_skin_checked="1">
                          <span class="u-font-size--huge u-font-weight--600"
                            >0</span
                          ><span
                            class="u-display--block u-text-transform--uppercase"
                            >Reservas</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-line-height--1 u-1/1"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small"
                        bis_skin_checked="1"
                      >
                        <i
                          class="fa-light fa-map-location-dot u-font-size--huge clr--middle-gray"
                        ></i>
                        <div class="u-text-align--right" bis_skin_checked="1">
                          <span class="u-font-size--huge u-font-weight--600"
                            >0</span
                          ><span
                            class="u-display--block u-text-transform--uppercase"
                            >Destinos</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-line-height--1 u-1/1"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small"
                        bis_skin_checked="1"
                      >
                        <i
                          class="fa-light fa-square-h u-font-size--huge clr--middle-gray"
                        ></i>
                        <div class="u-text-align--right" bis_skin_checked="1">
                          <span class="u-font-size--huge u-font-weight--600"
                            >0</span
                          ><span
                            class="u-display--block u-text-transform--uppercase"
                            >Hoteles</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-line-height--1 u-1/1"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small"
                        bis_skin_checked="1"
                      >
                        <i
                          class="fa-light fa-plane-departure u-font-size--huge clr--middle-gray"
                        ></i>
                        <div class="u-text-align--right" bis_skin_checked="1">
                          <span class="u-font-size--huge u-font-weight--600"
                            >0</span
                          ><span
                            class="u-display--block u-text-transform--uppercase"
                            >Transportes</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Datos -->

                <div class="pb-20" bis_skin_checked="1">
                  <h2 class="c-title--main u-font-weight--600 mt-0">
                    {{ $func.parseIso("payments") }}
                  </h2>
                  <div
                    class="o-box u-border-radius u-border--full bdr-clr--lighter-gray mt-10"
                    bis_skin_checked="1"
                    v-if="true"
                  >
                    <ModalStripeRenovation
                      :contractId="ContratoId"
                      v-if="ContratoId"
                    />
                  </div>
                </div>

                <div class="pb-20" bis_skin_checked="1">
                  <h2 class="c-title--main u-font-weight--600 mt-0">
                    {{ $func.parseIso("subscription") }}
                  </h2>
                  <div
                    class="o-box u-border-radius u-border--full bdr-clr--lighter-gray mt-10"
                    bis_skin_checked="1"
                    v-if="false"
                  >
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div
                        class="u-display--inline-block o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-border--full u-line-height--1 mb-20"
                        style="border-left-width: 4px; border-color: #48c774"
                        bis_skin_checked="1"
                      >
                        <div
                          class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small u-gap--default@md"
                          bis_skin_checked="1"
                        >
                          <i
                            class="fas fa-check-circle u-font-size--huge"
                            style="font-size: 60px; color: #48c774"
                          ></i>
                          <div class="" bis_skin_checked="1">
                            <div style="display: flex; align-items: center">
                              <span
                                class="total-available-rewards u-font-weight--600"
                                style="font-size: 25px"
                                >Annual Plan</span
                              >
                              <span
                                class="c-tag c-tag--md"
                                style="
                                  margin: 10px;
                                  background-color: #48c774;
                                  color: white;
                                "
                                >Active</span
                              >
                            </div>

                            <span class="u-display--block"
                              >Billing Annual - Next Invoice on
                              <b>August 30, 2025 for $199 USD</b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="o-box u-border-radius u-border--full bdr-clr--lighter-gray mt-10"
                    bis_skin_checked="1"
                    v-if="true"
                  >
                    <div
                      class="o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div
                        class="u-display--inline-block o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-border--full u-line-height--1 mb-20"
                        style="border-left-width: 4px; border-color: #f14668"
                        bis_skin_checked="1"
                      >
                        <div
                          class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small u-gap--default@md"
                          bis_skin_checked="1"
                        >
                          <i
                            class="fas fa-times-circle u-font-size--huge"
                            style="font-size: 60px; color: #f14668"
                          ></i>
                          <div class="" bis_skin_checked="1">
                            <div style="display: flex; align-items: center">
                              <!-- <span
                                class="total-available-rewards u-font-weight--600"
                                style="font-size: 25px"
                                >Annual Plan</span
                              > -->
                              <span
                                class="c-tag c-tag--md"
                                style="
                                  margin: 10px;
                                  background-color: #f14668;
                                  color: white;
                                "
                                >Expired</span
                              >
                            </div>

                            <span class="u-display--block"
                              >Billing Annual - Next Invoice on
                              <b>August 30, 2025 for $199 USD</b>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="u-display--inline-block o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-border--full u-line-height--1 mb-20"
                        style="
                          border-color: #5433ff;
                          margin-left: 20px;
                          border-width: 2px;
                        "
                        bis_skin_checked="1"
                        id="bton-annual-plan"
                      >
                        <div
                          class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small u-gap--default@md"
                          bis_skin_checked="1"
                        >
                          <i
                            class="fas fa-hand-holding-usd u-font-size--huge"
                            style="font-size: 30px; color: #5433ff"
                          ></i>
                          <div
                            class=""
                            bis_skin_checked="1"
                            @click="payRenewal"
                          >
                            <span
                              class="total-available-rewards u-font-weight--600"
                              style="font-size: 20px"
                              >Pagar Ahora</span
                            >

                            <span
                              class="u-display--block"
                              style="font-size: 14px; padding-top: 7px"
                              >Powered by <b style="color: #5433ff">Stripe</b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pb-20" bis_skin_checked="1">
                  <h2 class="c-title--main u-font-weight--600 mt-0">
                    {{ $func.parseIso("owner") }}
                  </h2>
                  <div
                    class="o-box u-border-radius u-border--full bdr-clr--lighter-gray mt-10"
                    bis_skin_checked="1"
                  >
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          id="dashboardForm:j_id_8h"
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("name")
                          }}</span></label
                        ><input
                          v-model="member.Nombre"
                          id="dashboardForm:referenceBookings"
                          name="dashboardForm:referenceBookings"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                        />
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("lastname")
                          }}</span></label
                        ><input
                          v-model="member.ApellidoPaterno"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                        />
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("email")
                          }}</span></label
                        ><input
                          v-model="member.Email"
                          disabled
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                        />
                      </div>
                    </div>
                    <br />
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="o-layout__item u-12/12" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget c-sb-input__label ui-required"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("cellphone")
                          }}</span
                          ><span class="ui-outputlabel-rfi">*</span></label
                        >
                        <div
                          class="o-layout o-layout--fix o-layout--middle c-sb-input--combined"
                          bis_skin_checked="1"
                        >
                          <div
                            class="c-sb-input o-layout__item u-1/2"
                            bis_skin_checked="1"
                          >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop dev-time carrental-pickup-calendar-container"
                              bis_skin_checked="1"
                            >
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  v-model="member.LadaTelefono"
                                  style="min-height: 40px"
                                >
                                  <option :value="null">
                                    <!-- {{ $func.parseIso("countrySelected") }} -->
                                  </option>
                                  <option
                                    :value="item.Lada"
                                    v-for="item in countries"
                                  >
                                    +{{ item.Lada }} ({{ item.Id }})
                                  </option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-globe"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div
                            class="c-sb-input o-layout__item u-1/2"
                            bis_skin_checked="1"
                          >
                            <div
                              class="c-sb-input__addicon"
                              bis_skin_checked="1"
                            >
                              <div
                                class="dev-departureTimeSelector u-1/1 u-position--relative dev-time-picker"
                                bis_skin_checked="1"
                              >
                                <div
                                  class="sb-dropdown dropdown-toggler call-pp-help ui-commandlink"
                                  role="button"
                                >
                                  <input
                                    v-model="member.Telefono"
                                    type="text"
                                    class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="o-layout__item u-12/12" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget c-sb-input__label ui-required"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("phone")
                          }}</span
                          ><span class="ui-outputlabel-rfi">*</span></label
                        >
                        <div
                          class="o-layout o-layout--fix o-layout--middle c-sb-input--combined"
                          bis_skin_checked="1"
                        >
                          <div
                            class="c-sb-input o-layout__item u-1/2"
                            bis_skin_checked="1"
                          >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop dev-time carrental-pickup-calendar-container"
                              bis_skin_checked="1"
                            >
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  v-model="member.LadaCelular"
                                  style="min-height: 40px"
                                >
                                  <option :value="null">
                                    <!-- {{ $func.parseIso("countrySelected") }} -->
                                  </option>
                                  <option
                                    :value="item.Lada"
                                    v-for="item in countries"
                                  >
                                    +{{ item.Lada }} ({{ item.Id }})
                                  </option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-globe"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div
                            class="c-sb-input o-layout__item u-1/2"
                            bis_skin_checked="1"
                          >
                            <div
                              class="c-sb-input__addicon"
                              bis_skin_checked="1"
                            >
                              <div
                                class="dev-departureTimeSelector u-1/1 u-position--relative dev-time-picker"
                                bis_skin_checked="1"
                              >
                                <div
                                  class="sb-dropdown dropdown-toggler call-pp-help ui-commandlink"
                                  role="button"
                                >
                                  <input
                                    v-model="member.Celular"
                                    type="text"
                                    class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("birthdate")
                          }}</span></label
                        >
                        <div class="c-sb-input__addicon">
                          <input
                            v-model="member.FechaNacimiento"
                            type="date"
                            style="padding-left: 3em"
                            id="datepicker-custom"
                            class="ui-inputfield ui-inputdate ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                          />
                          <i class="c-sb-input__icon fa-light fa-calendar"></i>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          id="dashboardForm:j_id_8h"
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("country")
                          }}</span></label
                        >
                        <div
                          class="c-sb-input__addicon c-sb-input__addicon--drop"
                        >
                          <select
                            size="1"
                            class="c-sb-input__field dev-select-country"
                            v-model="member.Pais"
                            style="min-height: 40px"
                          >
                            <option :value="null">
                              {{ $func.parseIso("countrySelected") }}
                            </option>
                            <option :value="item.Id" v-for="item in countries">
                              {{ iso === "en" ? item.NombreEn : item.Nombre }}
                            </option>
                          </select>
                          <i
                            class="c-sb-input__icon fa-light fa-map-marker-alt"
                          ></i>
                        </div>
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("city")
                          }}</span></label
                        ><input
                          v-model="member.Ciudad"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                        />
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("address")
                          }}</span></label
                        ><input
                          v-model="member.Direccion"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                        />
                      </div>
                    </div>
                    <br />
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("zip")
                          }}</span></label
                        ><input
                          v-model="member.CodigoPostal"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                        />
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          id="dashboardForm:j_id_8h"
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("nacionality")
                          }}</span></label
                        >
                        <div
                          class="c-sb-input__addicon c-sb-input__addicon--drop"
                        >
                          <select
                            size="1"
                            class="c-sb-input__field dev-select-country"
                            v-model="member.Nacionalidad"
                            style="min-height: 40px"
                          >
                            <option :value="null">
                              {{ $func.parseIso("countrySelected") }}
                            </option>
                            <option :value="item.Id" v-for="item in countries">
                              {{
                                iso === "en"
                                  ? item.NacionalidadEn
                                  : item.Nacionalidad
                              }}
                            </option>
                          </select>
                          <i
                            class="c-sb-input__icon fa-light fa-map-marker-alt"
                          ></i>
                        </div>
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          id="dashboardForm:j_id_8h"
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("maritalStatus")
                          }}</span></label
                        >
                        <div
                          class="c-sb-input__addicon c-sb-input__addicon--drop"
                        >
                          <select
                            size="1"
                            class="c-sb-input__field dev-select-country"
                            v-model="member.EstadoCivil"
                            style="min-height: 40px"
                          >
                            <option :value="null">
                              {{ $func.parseIso("selectOption") }}
                            </option>
                            <option
                              :value="item.Id"
                              v-for="item in CatEstadoCivil"
                            >
                              {{ iso === "en" ? item.NombreEn : item.Nombre }}
                            </option>
                          </select>
                          <i
                            class="c-sb-input__icon fa-light fa-map-marker-alt"
                          ></i>
                        </div>
                      </div>
                      <!-- <div class="u-1/1" bis_skin_checked="1"></div> -->
                      <!-- <button
                        class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only c-button bg-clr--brand"
                      >
                        <span class="ui-button-text ui-c">{{
                          $func.parseIso("update")
                        }}</span>
                      </button> -->
                    </div>
                    <br />
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="u-1/1" bis_skin_checked="1"></div>
                      <button
                        class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only c-button bg-clr--brand"
                      >
                        <span class="ui-button-text ui-c">{{
                          $func.parseIso("update")
                        }}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pb-20" bis_skin_checked="1" v-if="coMember">
                  <h2 class="c-title--main u-font-weight--600 mt-0">
                    {{ $func.parseIso("coOwner") }}
                  </h2>
                  <div
                    class="o-box u-border-radius u-border--full bdr-clr--lighter-gray mt-10"
                    bis_skin_checked="1"
                  >
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          id="dashboardForm:j_id_8h"
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("name")
                          }}</span></label
                        ><input
                          v-model="coMember.Nombre"
                          id="dashboardForm:referenceBookings"
                          name="dashboardForm:referenceBookings"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                        />
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("lastname")
                          }}</span></label
                        ><input
                          v-model="coMember.ApellidoPaterno"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                        />
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("email")
                          }}</span></label
                        ><input
                          v-model="coMember.Email"
                          disabled
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                        />
                      </div>
                    </div>
                    <br />

                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="o-layout__item u-12/12" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget c-sb-input__label ui-required"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("cellphone")
                          }}</span
                          ><span class="ui-outputlabel-rfi">*</span></label
                        >
                        <div
                          class="o-layout o-layout--fix o-layout--middle c-sb-input--combined"
                          bis_skin_checked="1"
                        >
                          <div
                            class="c-sb-input o-layout__item u-1/2"
                            bis_skin_checked="1"
                          >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop dev-time carrental-pickup-calendar-container"
                              bis_skin_checked="1"
                            >
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  v-model="coMember.LadaTelefono"
                                  style="min-height: 40px"
                                >
                                  <option :value="null">
                                    <!-- {{ $func.parseIso("countrySelected") }} -->
                                  </option>
                                  <option
                                    :value="item.Lada"
                                    v-for="item in countries"
                                  >
                                    +{{ item.Lada }} ({{ item.Id }})
                                  </option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-globe"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div
                            class="c-sb-input o-layout__item u-1/2"
                            bis_skin_checked="1"
                          >
                            <div
                              class="c-sb-input__addicon"
                              bis_skin_checked="1"
                            >
                              <div
                                class="dev-departureTimeSelector u-1/1 u-position--relative dev-time-picker"
                                bis_skin_checked="1"
                              >
                                <div
                                  class="sb-dropdown dropdown-toggler call-pp-help ui-commandlink"
                                  role="button"
                                >
                                  <input
                                    v-model="coMember.Telefono"
                                    type="text"
                                    class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="o-layout__item u-12/12" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget c-sb-input__label ui-required"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("phone")
                          }}</span
                          ><span class="ui-outputlabel-rfi">*</span></label
                        >
                        <div
                          class="o-layout o-layout--fix o-layout--middle c-sb-input--combined"
                          bis_skin_checked="1"
                        >
                          <div
                            class="c-sb-input o-layout__item u-1/2"
                            bis_skin_checked="1"
                          >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop dev-time carrental-pickup-calendar-container"
                              bis_skin_checked="1"
                            >
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  v-model="coMember.LadaCelular"
                                  style="min-height: 40px"
                                >
                                  <option :value="null">
                                    <!-- {{ $func.parseIso("countrySelected") }} -->
                                  </option>
                                  <option
                                    :value="item.Lada"
                                    v-for="item in countries"
                                  >
                                    +{{ item.Lada }} ({{ item.Id }})
                                  </option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-globe"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div
                            class="c-sb-input o-layout__item u-1/2"
                            bis_skin_checked="1"
                          >
                            <div
                              class="c-sb-input__addicon"
                              bis_skin_checked="1"
                            >
                              <div
                                class="dev-departureTimeSelector u-1/1 u-position--relative dev-time-picker"
                                bis_skin_checked="1"
                              >
                                <div
                                  class="sb-dropdown dropdown-toggler call-pp-help ui-commandlink"
                                  role="button"
                                >
                                  <input
                                    v-model="coMember.Celular"
                                    type="text"
                                    class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("birthdate")
                          }}</span></label
                        >
                        <div class="c-sb-input__addicon">
                          <input
                            v-model="coMember.FechaNacimiento"
                            type="date"
                            style="padding-left: 3em"
                            id="datepicker-custom"
                            class="ui-inputfield ui-inputdate ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                          />
                          <i class="c-sb-input__icon fa-light fa-calendar"></i>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          id="dashboardForm:j_id_8h"
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("nacionality")
                          }}</span></label
                        >
                        <div
                          class="c-sb-input__addicon c-sb-input__addicon--drop"
                        >
                          <select
                            size="1"
                            class="c-sb-input__field dev-select-country"
                            v-model="coMember.Nacionalidad"
                            style="min-height: 40px"
                          >
                            <option :value="null">
                              {{ $func.parseIso("countrySelected") }}
                            </option>
                            <option :value="item.Id" v-for="item in countries">
                              {{
                                iso === "en"
                                  ? item.NacionalidadEn
                                  : item.Nacionalidad
                              }}
                            </option>
                          </select>
                          <i
                            class="c-sb-input__icon fa-light fa-map-marker-alt"
                          ></i>
                        </div>
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <label
                          id="dashboardForm:j_id_8h"
                          class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                          for="dashboardForm:booking"
                          ><span class="ui-outputlabel-label">{{
                            $func.parseIso("maritalStatus")
                          }}</span></label
                        >
                        <div
                          class="c-sb-input__addicon c-sb-input__addicon--drop"
                        >
                          <select
                            size="1"
                            class="c-sb-input__field dev-select-country"
                            v-model="coMember.EstadoCivil"
                            style="min-height: 40px"
                          >
                            <option :value="null">
                              {{ $func.parseIso("selectOption") }}
                            </option>
                            <option
                              :value="item.Id"
                              v-for="item in CatEstadoCivil"
                            >
                              {{ iso === "en" ? item.NombreEn : item.Nombre }}
                            </option>
                          </select>
                          <i
                            class="c-sb-input__icon fa-light fa-map-marker-alt"
                          ></i>
                        </div>
                      </div>
                      <div class="u-1/1" bis_skin_checked="1"></div>
                    </div>
                    <br />
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="u-1/1" bis_skin_checked="1"></div>
                      <button
                        class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only c-button bg-clr--brand"
                      >
                        <span class="ui-button-text ui-c">{{
                          $func.parseIso("update")
                        }}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pb-20" bis_skin_checked="1">
                  <h2 class="c-title--main u-font-weight--600 mt-0">
                    {{ $func.parseIso("security") }}
                  </h2>
                  <div
                    class="o-box u-border-radius u-border--full bdr-clr--lighter-gray mt-10"
                    bis_skin_checked="1"
                  >
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--end u-gap--small"
                      bis_skin_checked="1"
                    >
                      <div class="u-1/1" bis_skin_checked="1">
                        <div class="c-icon-escort">
                          <label
                            class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                            for="dashboardForm:booking"
                            ><span class="ui-outputlabel-label">{{
                              $func.parseIso("newPassword")
                            }}</span></label
                          >
                          <span
                            class="ui-password ui-password-masked ui-inputwrapper ui-input-icon-right"
                            style="cursor: pointer"
                          >
                            <input
                              v-model="password"
                              :type="isShowPass ? 'text' : 'password'"
                              class="ui-inputfield ui-widget ui-state-default ui-corner-all c-icon-escort__main c-text-input login-password-input"
                              autocomplete="current-password"
                              placeholder="..." />
                            <i
                              id="login-form:login-content:login:j_password_mask"
                              class="ui-password-icon"
                              @click="showPass"
                            >
                            </i
                          ></span>
                        </div>
                      </div>
                      <div class="u-1/1" bis_skin_checked="1">
                        <div class="c-icon-escort">
                          <label
                            class="ui-outputlabel ui-widget u-font-weight--400 m-0 u-1/1"
                            for="dashboardForm:booking"
                            ><span class="ui-outputlabel-label">{{
                              $func.parseIso("confirmPassword")
                            }}</span></label
                          >
                          <span
                            class="ui-password ui-password-masked ui-inputwrapper ui-input-icon-right"
                            style="cursor: pointer"
                          >
                            <input
                              v-model="newpassword"
                              :type="isShowPass ? 'text' : 'password'"
                              class="ui-inputfield ui-widget ui-state-default ui-corner-all c-icon-escort__main c-text-input login-password-input"
                              autocomplete="current-password"
                              placeholder="..." />
                            <i
                              id="login-form:login-content:login:j_password_mask"
                              class="ui-password-icon"
                              @click="showPass"
                            >
                            </i
                          ></span>
                        </div>
                      </div>
                      <div class="u-1/2" bis_skin_checked="1">
                        <button
                          class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only c-button bg-clr--brand"
                        >
                          <span class="ui-button-text ui-c">{{
                            $func.parseIso("updatePassword")
                          }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="u-3/4@lg" bis_skin_checked="1" v-if="false">
                <div
                  class="c-page-hero c-page-hero--small"
                  bis_skin_checked="1"
                >
                  <div
                    class="c-page-hero__wrapper-img c-page-hero__wrapper-img--disable-mask u-border-radius"
                    bis_skin_checked="1"
                  >
                    <img
                      data-src="https://booking.kovayvacations.com.mx/javax.faces.resource/images/fullwidth2.jpg"
                      src="https://booking.kovayvacations.com.mx/javax.faces.resource/images/fullwidth2.jpg"
                      data-index=""
                      data-error-src="https://booking.kovayvacations.com.mx/javax.faces.resource/images/no-photo-XS.jpg"
                      onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                      loading="lazy"
                      alt=""
                      width=""
                      height=""
                      onclick=""
                      style=""
                      class="lazy-img c-page-hero__img u-image-object--cover u-image-object--full loaded"
                      data-ll-status="loaded"
                    />
                  </div>
                </div>
                <div
                  class="o-flexbox@sm o-flexbox--generic-row@sm u-align-items--center@sm u-align-items--end@md pb-20"
                  bis_skin_checked="1"
                >
                  <div
                    class="o-flexbox u-gap--small u-align-items--center"
                    bis_skin_checked="1"
                  >
                    <i
                      class="o-flexbox u-justify-content--center u-align-items--center u-border-radius--round u-border--full bdr-clr--white clr--dark-gray bg-clr--lightest-gray fa-regular fa-user u-font-size--giant u-border-width--4 u-position--relative u-zindex--2"
                      style="width: 110px; height: 110px; margin-top: -25px"
                    ></i>
                    <div
                      class="pb-10"
                      style="width: calc(100% - 120px)"
                      bis_skin_checked="1"
                    >
                      <h2
                        class="u-font-size--big u-font-size--bigger@sm u-font-weight--600 u-line-height--1 m-0"
                      >
                        Jose Daza
                      </h2>
                      <span
                        class="u-font-size--small u-font-size--default@md u-line-height--1"
                      >
                        kovay2_jdza@mail.com
                      </span>
                    </div>
                  </div>
                  <!-- <div
                    class="u-display--none u-display--block@sm pb-20@md"
                    bis_skin_checked="1"
                  >
                    <a
                      id="dashboardForm:j_id_3j"
                      href="#"
                      class="ui-commandlink ui-widget c-button bdr-clr--brand mb-5@md"
                      aria-label=""
                      onclick='PrimeFaces.ab({s:"dashboardForm:j_id_3j",f:"dashboardForm",u:"dashboardForm"});return false;'
                    >
                      Mis reservas
                    </a>
                  </div> -->
                </div>
                <div
                  class="u-display--none u-display--block@sm o-container o-container--large o-container--fit pb-30"
                  bis_skin_checked="1"
                >
                  <div
                    class="o-flexbox@sm o-flexbox--generic-row@sm u-gap--default u-justify-content--between"
                    bis_skin_checked="1"
                  >
                    <div
                      class="o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-line-height--1 u-1/1"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small"
                        bis_skin_checked="1"
                      >
                        <i
                          class="fa-light fa-calendar-check u-font-size--huge clr--dark-gray"
                        ></i>
                        <div class="u-text-align--right" bis_skin_checked="1">
                          <span class="u-font-size--huge u-font-weight--600"
                            >0</span
                          ><span
                            class="u-display--block u-text-transform--uppercase"
                            >Reservas</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-line-height--1 u-1/1"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small"
                        bis_skin_checked="1"
                      >
                        <i
                          class="fa-light fa-map-location-dot u-font-size--huge clr--middle-gray"
                        ></i>
                        <div class="u-text-align--right" bis_skin_checked="1">
                          <span class="u-font-size--huge u-font-weight--600"
                            >0</span
                          ><span
                            class="u-display--block u-text-transform--uppercase"
                            >Destinos</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-line-height--1 u-1/1"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small"
                        bis_skin_checked="1"
                      >
                        <i
                          class="fa-light fa-square-h u-font-size--huge clr--middle-gray"
                        ></i>
                        <div class="u-text-align--right" bis_skin_checked="1">
                          <span class="u-font-size--huge u-font-weight--600"
                            >0</span
                          ><span
                            class="u-display--block u-text-transform--uppercase"
                            >Hoteles</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="o-box--default--horizontal o-box--small--vertical u-box-shadow--soft u-border-radius--big u-line-height--1 u-1/1"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center u-gap--small"
                        bis_skin_checked="1"
                      >
                        <i
                          class="fa-light fa-plane-departure u-font-size--huge clr--middle-gray"
                        ></i>
                        <div class="u-text-align--right" bis_skin_checked="1">
                          <span class="u-font-size--huge u-font-weight--600"
                            >0</span
                          ><span
                            class="u-display--block u-text-transform--uppercase"
                            >Transportes</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pb-20" bis_skin_checked="1">
                  <h2 class="c-title--main u-font-weight--600 mt-0">
                    Mis datos
                  </h2>
                  <div
                    class="o-flexbox@sm u-flex-wrap--wrap"
                    style="margin: 0 -5px"
                    bis_skin_checked="1"
                  >
                    <div
                      class="o-box--tiny u-1/1 u-1/2@sm u-1/4@md"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-box--small pl-20 u-box-shadow--soft u-border--left u-border-radius bdr-clr--middle-gray u-border-width--4 u-line-height--1 u-height--full"
                        bis_skin_checked="1"
                      >
                        <div
                          class="o-flexbox u-align-items--center u-gap--small u-height--full"
                          bis_skin_checked="1"
                        >
                          <i
                            class="fa-light fa-user u-font-size--big clr--dark-gray"
                          ></i>
                          <div
                            style="width: calc(100% - 40px)"
                            bis_skin_checked="1"
                          >
                            <span
                              class="u-display--block u-font-size--small u-font-weight--600 clr--dark-gray"
                              >Nombre</span
                            ><span
                              class="u-display--block clr--darkest-gray u-font-weight--600 u-ellipsis-inline tc-tooltip"
                              title=""
                              data-original-title="Jose Daza"
                              >Jose Daza</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="o-box--tiny u-1/1 u-1/2@sm u-1/4@md"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-box--small pl-20 u-box-shadow--soft u-border--left u-border-radius bdr-clr--middle-gray u-border-width--4 u-line-height--1 u-height--full"
                        bis_skin_checked="1"
                      >
                        <div
                          class="o-flexbox u-align-items--center u-gap--small u-height--full"
                          bis_skin_checked="1"
                        >
                          <i
                            class="fa-light fa-envelope u-font-size--big clr--dark-gray"
                          ></i>
                          <div
                            style="width: calc(100% - 40px)"
                            bis_skin_checked="1"
                          >
                            <span
                              class="u-display--block u-font-size--small u-font-weight--600 clr--dark-gray"
                              >Correo electrónico</span
                            ><span
                              class="u-display--block clr--darkest-gray u-font-weight--600 u-ellipsis-inline tc-tooltip"
                              title=""
                              data-original-title="kovay2_jdza@mail.com"
                              >kovay2_jdza@mail.com</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="o-box--tiny u-1/1 u-1/2@sm u-1/4@md"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-box--small pl-20 u-box-shadow--soft u-border--left u-border-radius bdr-clr--middle-gray u-border-width--4 u-line-height--1 u-height--full"
                        bis_skin_checked="1"
                      >
                        <div
                          class="o-flexbox u-align-items--center u-gap--small u-height--full"
                          bis_skin_checked="1"
                        >
                          <i
                            class="fa-light fa-phone u-font-size--big clr--dark-gray"
                          ></i>
                          <div bis_skin_checked="1">
                            <span
                              class="u-display--block u-font-size--small u-font-weight--600 clr--dark-gray"
                              >Teléfono</span
                            ><span
                              class="clr--darkest-gray u-font-weight--600"
                            ></span
                            ><span class="clr--darkest-gray u-font-weight--600"
                              >+18772123834</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="o-box--tiny u-1/1 u-1/2@sm u-1/4@md"
                      bis_skin_checked="1"
                    >
                      <div
                        class="o-box--small pl-20 u-box-shadow--soft u-border--left u-border-radius bdr-clr--middle-gray u-border-width--4 u-line-height--1 u-height--full"
                        bis_skin_checked="1"
                      >
                        <div
                          class="o-flexbox u-align-items--center u-gap--small u-height--full"
                          bis_skin_checked="1"
                        >
                          <i
                            class="fa-light fa-phone u-font-size--big clr--dark-gray"
                          ></i>
                          <div bis_skin_checked="1">
                            <span
                              class="u-display--block u-font-size--small u-font-weight--600 clr--dark-gray"
                              >LoginID</span
                            ><span
                              class="clr--darkest-gray u-font-weight--600"
                            ></span
                            ><span class="clr--darkest-gray u-font-weight--600"
                              >KOV1JDZA</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="o-block o-block--tiny" bis_skin_checked="1">
                  <h3
                    class="c-title--main u-font-weight--600 o-block__item u-font-size--big"
                  >
                    Renovaciones
                  </h3>
                  <div
                    class="o-block__item c-table o-table o-block__item mb-10"
                    bis_skin_checked="1"
                  >
                    <div
                      class="c-table__row c-table__subheading o-table__row o-table__section__heading o-layout o-layout--middle u-border-radius--top u-display--none u-display--block@md"
                      bis_skin_checked="1"
                    >
                      <div
                        class="c-table__col o-table__col o-layout__item u-1/1 u-1/4@md u-text-align--center@md"
                        bis_skin_checked="1"
                      >
                        <span
                          class="u-display--none u-display--inline-block@md"
                        >
                          Fecha de Pago
                        </span>
                      </div>
                      <div
                        class="c-table__col o-table__col u-padding--null o-layout__item u-display--none u-display--inline-block@md u-flex--11 u-3/4"
                        bis_skin_checked="1"
                      >
                        <div
                          class="c-table__row o-table__row o-layout"
                          bis_skin_checked="1"
                        >
                          <div
                            class="c-table__col o-table__col o-layout__item u-1/4"
                            bis_skin_checked="1"
                          >
                            <span class="u-display--none u-display--inline@sm">
                              Periodos
                            </span>
                          </div>
                          <div
                            class="c-table__col o-table__col o-layout__item u-1/4"
                            bis_skin_checked="1"
                          >
                            <span class="u-display--none u-display--inline@sm">
                              Monto
                            </span>
                          </div>
                          <div
                            class="c-table__col o-table__col o-layout__item u-1/4"
                            bis_skin_checked="1"
                          >
                            Desde
                          </div>
                          <div
                            class="c-table__col o-table__col o-layout__item u-1/4"
                            bis_skin_checked="1"
                          >
                            Hasta
                          </div>
                          <!-- <div
                            class="c-table__col o-table__col o-layout__item u-1/5 u-text-align--right"
                            bis_skin_checked="1"
                          >
                            Fecha de caducidad
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div
                      class="c-table__section o-table__section u-bdr-clr--transparent"
                      bis_skin_checked="1"
                    >
                      <div class="mt-10" bis_skin_checked="1">
                        <div
                          class="c-no-results bg-clr--lightest-gray u-border-radius--big o-box--big--vertical o-box--default--horizontal"
                          bis_skin_checked="1"
                        >
                          <div
                            class="u-display--flex u-align-items--center u-justify-content--center u-gap--default"
                            bis_skin_checked="1"
                          >
                            <div
                              style="width: 65px; min-width: 65px"
                              bis_skin_checked="1"
                            >
                              <img
                                data-src="https://booking.kovayvacations.com.mx/javax.faces.resource/images/no-results.svg"
                                src="https://booking.kovayvacations.com.mx/javax.faces.resource/images/no-results.svg"
                                data-index=""
                                data-error-src="/javax.faces.resource/images/no-photo-XS.jpg"
                                onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                                loading="lazy"
                                alt="no results"
                                width=""
                                height=""
                                onclick=""
                                style="position: static"
                                class="lazy-img u-1/1 u-opacity--5 u-image-object--cover u-image-object--full loaded"
                                data-ll-status="loaded"
                              />
                            </div>
                            <div class="clr--middle-gray" bis_skin_checked="1">
                              <span
                                class="u-display--inline-block o-box--small--horizontal o-box--tiny--vertical u-border--left bdr-clr--light-gray u-border-width--2 u-box-shadow clr--middle-gray u-font-size--small u-font-weight--600 u-hide-empty mb-10"
                              ></span
                              ><span
                                class="u-display--block u-font-size--bigger u-font-weight--700 u-line-height--1"
                                >Oops!...</span
                              ><span
                                class="c-no-results__message u-display--block pt-5"
                                style="max-width: 350px"
                                >No se encontraron resultados</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <Footer :isDefault="club && false" :club="club" /> -->
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import {
  setSearchWeekObject,
  getClubObject,
  getMemberObject,
} from "../utils/sesions";
import { detect } from "detect-browser";
const imgs = [
  "https://booking.kovayvacations.com.mx/javax.faces.resource/index-background/background-1.jpg",
  "https://booking.kovayvacations.com.mx/javax.faces.resource/index-background/background-2.jpg",
  "https://booking.kovayvacations.com.mx/javax.faces.resource/index-background/background-0.jpg",
  "https://booking.kovayvacations.com.mx/javax.faces.resource/index-background/background-5.jpg",
];

const SKIN_VACANCY = 951;

import ModalStripeRenovation from "./ModalStripeRenovation.vue";

export default {
  data() {
    return {
      country: null,
      state: null,
      city: null,
      checkIn: null,
      checkOut: null,
      countries: [],
      states: [],
      cities: [],
      checkInList: [],
      checkOutList: [],
      token: null,
      club: null,
      iso: null,
      textLoading: "",
      intervalId: null,
      tab: 1,
      cruise: {
        destination: 1,
        port: "",
        cruiseline: "",
        ship: "",
        month: "",
        numberOfDays: "",
      },
      allDestinations: [],
      allPorts: [],
      allCruiseLines: [],
      allShips: [],
      allMonths: [],
      allLengths: [],
      btnColor: null,
      browser: null,
      ModuloCruceros: false,
      setImg: imgs[Math.floor(Math.random() * imgs.length)],
      isShowPass: false,

      member: {
        Nombre: null,
        ApellidoPaterno: null,
        Direccion: null,
        Pais: null,
        Estado: null,
        Ciudad: null,
        CodigoPostal: null,
        LadaTelefono: null,
        Telefono: null,
        LadaCelular: null,
        Celular: null,
        Email: null,
        FechaNacimiento: null,
        EstadoCivil: null,
        ContratoId: {
          LoginID: null,
          Numero: null,
        },
      },
      coMember: null,
      password: null,
      newpassword: null,
      CatEstadoCivil: [],
      ContratoId: null,
    };
  },
  components: {
    Header,
    Footer,
    ModalStripeRenovation,
  },
  async created() {
    // await this.checkSesion();
    await this.listCountries();
    await this.listCatEstadoCivil();
    // await this.listCruises();

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });

    const __club = getClubObject();
    if (__club?.ColorPrimarioHex) this.btnColor = __club?.ColorPrimarioHex;
    if (__club?.ModuloCruceros) this.ModuloCruceros = __club?.ModuloCruceros;

    const __member = getMemberObject();
    this.ContratoId = __member?.ContratoId;

    const browser = detect();
    this.browser = browser.name;
    console.log("browser.name", browser.name);

    await this.getProfile();
  },
  methods: {
    showPass() {
      this.isShowPass = !this.isShowPass;
    },
    async getProfile() {
      try {
        const memberObject = getMemberObject();

        console.log("memberObject", memberObject);

        if (memberObject?._id) {
          const { data } = await this.$axios.get(
            `/catcher/members?idMongo=${memberObject?._id}`
          );
          this.member = data.member;

          this.coMember = data?.member?.coOwner;

          if (this.member.FechaNacimiento) {
            this.member.FechaNacimiento = this.$moment(
              this.member.FechaNacimiento
            ).format("YYYY-MM-DD");
          }

          if (this.coMember.FechaNacimiento) {
            this.coMember.FechaNacimiento = this.$moment(
              this.coMember.FechaNacimiento
            ).format("YYYY-MM-DD");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async listCountries() {
      try {
        const { data } = await this.$axios.get("/catalogue/CatPaises");
        this.countries = data;
        // this.countries = this.countries.sort((a, b) =>
        //   a.Country === "USA" ||
        //   a.Country === "Mexico" ||
        //   a.Country === "Dominican Republic"
        //     ? -1
        //     : 0
        // );
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    async listCatEstadoCivil() {
      try {
        const { data } = await this.$axios.get("/catalogue/CatEstadoCivil");
        this.CatEstadoCivil = data;
      } catch (error) {
        console.log(error);
      }
    },
    async listState() {
      try {
        const { data } = await this.$axios.get(
          `/week/states?country=${this.country}`
        );
        this.states = data.states.sort((a, b) =>
          a.NOMBRE_ESTADO > b.NOMBRE_ESTADO
            ? 1
            : a.NOMBRE_ESTADO < b.NOMBRE_ESTADO
            ? -1
            : 0
        );
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    async listCities(state = null) {
      try {
        let query = `?country=${this.country}`;
        if (state) query += `&state=${state}`;
        const { data } = await this.$axios.get(`/week/cities${query}`);
        this.cities = data.cities;
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    async checkMonths() {
      if (this.country && this.city) {
        try {
          const { data } = await this.$axios.get(
            `/week/check-months?country=${this.country}&city=${this.city}`
          );
          this.checkInList = data.checkIn;
          this.checkOutList = data.checkOut;
          // console.log(this.countries);
        } catch (error) {
          console.log(error);
        }
      }
    },
    async listWeeks() {
      if (this.country && this.city && this.checkIn && this.checkOut) {
        this.textLoading += ".";
        setSearchWeekObject({
          country: this.country,
          city: this.city,
          checkIn: this.checkIn,
          checkOut: this.checkOut,
          state: this.state,
        });
        this.intervalId = setInterval(
          (_this) => {
            // console.log("_this.textLoading", _this.textLoading);
            _this.textLoading += ".";
            if (_this.textLoading.split("").length >= 4) {
              clearInterval(_this.intervalId);
              // const __path = _this.$router.resolve({ name: "ResortList" });
              _this.textLoading = "";
              _this.country = null;
              _this.state = null;
              _this.city = null;
              _this.checkIn = null;
              _this.checkOut = null;

              if (this.browser === "ios") window.location.href = "/resortlist";
              else window.open("/resortlist", "_blank");
              // _this.$router.push(`/resortlist`);
            }
          },
          800,
          this
        );
      }
    },
    checkCountry() {
      if (this.country) {
        if (this.checkFormState()) this.listState();
        else this.listCities();
      }
    },
    checkState() {
      if (this.country && this.state) this.listCities(this.state);
    },
    formatDate(date) {
      return this.$moment(date).format("LL");
    },
    checkFormState() {
      if (this.country === "USA" || this.country === "Canada") return true;
      return false;
    },
    async checkSesion() {
      try {
        const { token } = this.$route.query;

        if (!token) {
          const urllogin = localStorage.getItem("UrlLogin");
          // console.log('urllogin', urllogin)
          // console.log(`https://${process.env.VUE_APP_URL_LOGIN_DEFAULT}`)
          if (urllogin) window.location.href = `https://${urllogin}`;
          else
            window.location.href = `https://${process.env.VUE_APP_URL_LOGIN_DEFAULT}`;
        }

        this.token = token;

        const { data } = await this.$axios.get(`/auth/sesion?token=${token}`);
        if (data.success) {
          this.$bus.emit("sesion", { sesion: data.sesion, data: data.data });
          if (data?.data?.club) this.club = data?.data?.club;
        } else {
          if (data.sesion)
            window.location.href = `https://${data.sesion.UrlLogin}`;
          else
            window.location.href = `https://${process.env.VUE_APP_URL_LOGIN_DEFAULT}`;
        }
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    selectTab(tab) {
      this.tab = tab;
    },
    async listCruises(resetFilters = false) {
      try {
        let mon = this.cruise.month;
        if (!mon || mon === "")
          mon = this.$moment()
            .add(2, "months")
            .startOf("month")
            .format("MM/DD/YYYY");

        const { data } = await this.$axios.get(
          `https://cs.cruisebase.com/csa/GetSCriteria?skin=${SKIN_VACANCY}&lid=${this.iso}&did=${this.cruise.destination}&mon=${mon}&pid=${this.cruise.port}&vid=${this.cruise.cruiseline}&sid=${this.cruise.ship}&len=${this.cruise.numberOfDays}`
        );

        if (data.zones) {
          this.allDestinations = data.zones.filter((d) => d[1] !== 1);
        }
        if (data.ports) {
          this.allPorts = data.ports.filter((d) => d[1] !== 1);
        }
        if (data.vendors) {
          this.allCruiseLines = data.vendors.filter((d) => d[1] !== 1);
        }
        if (data.ships) {
          this.allShips = data.ships.filter((d) => d[1] !== 1);
        }
        if (data.months) {
          const existsDefault = data.months.find((d) => d[1] === 2);
          if (existsDefault) this.cruise.month = existsDefault[0];
          this.allMonths = data.months.filter((d) => d[1] !== 1);
        }
        if (data.days) {
          this.allLengths = data.days.filter((d) => d[1] !== 1);
        }

        if (resetFilters) {
          this.cruise.port = "";
          this.cruise.cruiseline = "";
          this.cruise.ship = "";
          this.cruise.month = "";
          this.cruise.numberOfDays = "";
        }

        console.log("data cruises", data);
      } catch (error) {
        console.log(error);
      }
    },
    findCruises() {
      const url = `https://cs.cruisebase.com/cs/forms/CruiseResultPage.aspx?skin=${SKIN_VACANCY}&phone=&lid=${
        this.iso
      }&logo=n&mon=${this.cruise.month}&did=${this.cruise.destination}&pid=${
        this.cruise.port
      }&vid=${this.cruise.cruiseline}&sid=${this.cruise.ship}&len=${
        this.cruise.numberOfDays
      }&nr=y&look=${process.env.VUE_APP_API_URL_STATIC}/cruise_${
        (localStorage.getItem("club") &&
          localStorage.getItem("club")?.Nomenclatura) ||
        "VR"
      }.css`;
      if (this.browser === "ios") window.location.href = url;
      else window.open(url, "_blank", "noopener, noreferrer");
    },
    resetFiltersCruises() {
      this.cruise.port = "";
      this.cruise.cruiseline = "";
      this.cruise.ship = "";
      this.cruise.month = "";
      this.cruise.numberOfDays = "";
    },
    bCloseModal() {
      console.log("cerrando");
    },
    async payRenewal() {
      console.log("payRenewal");
      await this.$modal.show("pay-checkout");
    },
  },
};
</script>

<style scoped>
:root {
  --s-color-brand-primary: #0472aa;
  --s-color-brand-primary-hsl-hue: 200;
  --s-color-brand-primary-hsl-saturation: 95%;
  --s-color-brand-primary-hsl-light: 34%;
  --s-color-brand-primary-red: 4;
  --s-color-brand-primary-green: 114;
  --s-color-brand-primary-blue: 170;

  --s-color-brand-dark: #00527c;
  --s-color-brand-dark-hsl-hue: 200;
  --s-color-brand-dark-hsl-saturation: 100%;
  --s-color-brand-dark-hsl-light: 24%;
  --s-color-brand-dark-red: 0;
  --s-color-brand-dark-green: 82;
  --s-color-brand-dark-blue: 124;

  --s-color-searchbox-background: #00527c;
  --s-color-searchbox-background-hsl-hue: 200;
  --s-color-searchbox-background-hsl-saturation: 100%;
  --s-color-searchbox-background-hsl-light: 24%;
  --s-color-searchbox-background-red: 0;
  --s-color-searchbox-background-green: 82;
  --s-color-searchbox-background-blue: 124;

  --s-color-searchbox-text: #393939;
  --s-color-searchbox-text-hsl-hue: 0;
  --s-color-searchbox-text-hsl-saturation: 0%;
  --s-color-searchbox-text-hsl-light: 22%;
  --s-color-searchbox-text-red: 57;
  --s-color-searchbox-text-green: 57;
  --s-color-searchbox-text-blue: 57;

  --s-color-footer-background: #00527c;
  --s-color-footer-background-hsl-hue: 200;
  --s-color-footer-background-hsl-saturation: 100%;
  --s-color-footer-background-hsl-light: 24%;
  --s-color-footer-background-red: 0;
  --s-color-footer-background-green: 82;
  --s-color-footer-background-blue: 124;

  --s-color-footer-text: #393939;
  --s-color-footer-text-hsl-hue: 0;
  --s-color-footer-text-hsl-saturation: 0%;
  --s-color-footer-text-hsl-light: 22%;
  --s-color-footer-text-red: 57;
  --s-color-footer-text-green: 57;
  --s-color-footer-text-blue: 57;
}

.noIcon {
  background: none;
}

.c-sb-input__field {
  padding-left: 40px;
}

/* @media (max-width: 1920) {
  .img-jdaza {
    height: 109%;
  }
}
@media (max-width: 1280px) {
  .img-jdaza {
    height: 123%;
  }
} */
.img-jdaza {
  height: 109%;
}

@media (max-width: 1280px) {
  .img-jdaza {
    height: 123%;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-9-22 3:49:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

/* #bton-annual-plan:hover {
  background-color: #1cc6ff;
  cursor: pointer;
} */

#bton-annual-plan:hover {
  -webkit-animation: wobble-hor-bottom 0.8s both;
  animation: wobble-hor-bottom 0.8s both;
  cursor: pointer;
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
} */

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="text"]:disabled {
  background-color: rgb(240, 240, 240);
  color: rgb(0, 0, 0);
  opacity: 1;
}
</style>
